import React, { useState } from "react";
import pepega from "../assets/pepega.png"
const bingoData = [
    [
        "Sorry we are going to start a bit late", 
        "You enter a correct flag but they didn't set the correct flag in ctfd", 
        "OSINT", 
        "Challenge has nothing to do with the category that its in", 
        "You found the flag but flag format is wrong",
        "You have to guess credentials but its based on the ctf name"
    ],
    [
        "Authors plugging their names everywhere", 
        "You download the challenge VM's so you can just mountfs and get the flag", 
        "Please don't use dirb :(", 
        "Challenge stolen from another CTF", 
        "No way to reset the machines if you break them",
        "Someone rooted the platform and deleted/leaked challenges"
    ],
    [
        "Author doesn't understand their own challenge", 
        "But i thought people would love this :(", 
        "⭐", 
        "You have to crack a hash but its only at the end of a huge wordlist", 
        "There are fake flags",
        "Cracking WPAPSK because hacking is just cracking wifi and hashes right?",
    ],
    [
        "There are pwn challenges but ropstar solves them", 
        "GUESSING", 
        "Flag is in the comments somewhere", 
        "Online ctf and has an obvious cheating issue", 
        "Intended solution is so shit that you didn't realise you solved it via an unintended method",
        "Pointless coding challenge"
    ],
    [
        "Mobile challenge solved with grep -R", 
        "Reverse challenge solved with strings", 
        "Reverse challenge but you can solve it with memory grep", 
        "Stego challenge with 0 creativity involved", 
        "You have to brute random.seed(time.time()) but correct timestamp is int",
        "You can find the flag via grep/strings, but its base64 so its safe /s"
    ],
  ];

const bingoRows=[false,false,false,false,false]

const Bingo = () => {
  const numRows = 5;
  const numCols = 5;

  // Create a state to track which cells have been clicked.
  const [clickedCells, setClickedCells] = useState(
    Array(numRows)
      .fill()
      .map(() => Array(numCols).fill(false)
    )
  );
  const [superBingoRows, setSuperBingo] = useState(
    Array(numRows)
      .fill(false)
  );
  clickedCells[2][2] = true

  // Function to handle cell click
  const handleCellClick = (row, col) => {
    const updatedClickedCells = [...clickedCells];
    updatedClickedCells[row][col] = !updatedClickedCells[row][col];
    setClickedCells(updatedClickedCells);
    clickedCells[2][2] = true
    for(let i=0;i<5;i++){
        bingoRows[i] = false;
    }
    for(let i=0;i<5;i++){
        let bingoed=true
        for(let j=0;j<5;j++){
            if(!clickedCells[i][j]) bingoed=false;
        }
        console.log(bingoed)
        if(bingoed){
            bingoRows[i] = true;
        }
    }
    let updatedSuperBingo = [...superBingoRows];
    for(let i=0;i<5;i++){
      if(!bingoRows[i]) updatedSuperBingo[i] = false;
      console.log(updatedSuperBingo)
    }
    setSuperBingo(updatedSuperBingo)
  };
  const handleSecretClick = (row) => {
    const updatedSuperBingo = [...superBingoRows];
    updatedSuperBingo[row] = !updatedSuperBingo[row];
    setSuperBingo(updatedSuperBingo);
    
  };

  return (
    <div className="grid">
      <table className="table table-bordered">
        <tbody>
            <tr>
            <th>B</th>
            <th>I</th>
            <th>N</th>
            <th>G</th>
            <th>O</th>
            <th style={{fontSize:"30px", webkitTextStrokeWidth:"1px"}}>SECRET DOUBLE WHAMMY</th>
            </tr>
          {Array.from({ length: numRows }).map((_, row) => (
            <tr key={row}>
              {Array.from({ length: numCols }).map((_, col) => (
                <td
                key={5}
                className={"cell " + (clickedCells[row][col] ? "clicked" : "not-clicked") + (bingoRows[row] && !superBingoRows[row] ? " bingoed" : "") + (superBingoRows[row] ? " superbingoed" : "")}
                data-row={row}   // Add data-row attribute
                data-col={col}   // Add data-col attribute
                onClick={() => handleCellClick(row, col)}
              >{bingoData[row][col]}</td>
              ))}
              {bingoRows[row] ? <td
                  key={5}
                  className={"cell not-clicked" + (superBingoRows[row] ? " superbingoed" : "")}
                  data-row={row}   // Add data-row attribute
                  data-col={5}   // Add data-col attribute
                  onClick={() => handleSecretClick(row)}
                >{bingoData[row][5]}</td> : <td className="cell not-clicked"><img className="pepega" src={pepega}></img></td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Bingo;