import logo from './logo.svg';
import './App.css';
import Bingo from './classes/bingo.jsx';

function App() {
  return (
  <Bingo></Bingo>    
  );
}

export default App;
